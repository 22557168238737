.square-button {
  width: 2.5vmin;
  padding: 1vmax;
  border: 0.1vmin solid white;
  height: 15vmax;
  margin-top: 2vmin;
  display: flex;
  align-content: space-around;
  justify-content: center;
}

a:link {
  text-decoration: none;
}

.square-button p {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  margin: 0;
  padding: 0;
  font-size: 2.5ex;
}
