
.home-content {
  display: flex;
  justify-content: flex-end;
  height: 100vmin;
}

.home {
  display:flex;
  justify-content: flex-end;
}

.aboutme {
  flex-grow: 1;
  margin-left: 18vmin;
  margin-right: 18vmin;
}
