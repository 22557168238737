$bg-dark: #000000;
$bg-light: #FAFAFA;

$primary-color: #EBEBEB;
$dark-primary-color: #CCCCCC;
$light-primary-color: #FAFAFA;

$secondary-color: #000000;
$dark-secondary-color: #666666;
$light-secondary-color: #999999;

$accent-color: #6600FF;
$dark-accent-color: #330099;
$light-accent-color: #9933FF;

// only dark mode
$primary-color-text: $primary-color;
$secondary-color-text: $dark-primary-color;

$divider-color: $light-primary-color;

$disabled-color: $dark-primary-color;


$font-family: "Body Text", "Noto Sans", "Nunito Sans", "PT Sans", sans-serif;
$code-font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

body {
  margin: 0;
  background-color: $bg-dark;
  font-family: $font-family;
}

code {
  font-family: $code-font-family;
}

h1 {
  color: $primary-color;
  font-size: 10vmax;
  font-family: "Body Text Large", $font-family;
  font-weight: bold;
}

p {
  color: $primary-color;
  font-size: 4vmax;
  font-family: "Body Text Regular", $font-family
}

a {
  color: $primary-color;
  font-family: "Body Text Regular", $font-family
}

.content {
  padding-left: 7vmax;
  padding-right: 1vmax;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%
}


/* Add some top padding to the page content to prevent sudden quick movement */

.sticky + .content {
  padding-top: 2.5vmin;
}



.small-font {
  font-size: clamp(0.5rem, 2.5vw, 1rem);
}

.medium-font {
  font-size: clamp(1rem, 2.5vw, 1.6rem);
}

.larger-font {
  font-size: clamp(2rem, 10vw, 4rem);
}

.page-title {
  text-align: initial;
  font-size: clamp(4rem, 10vw, 6rem);
  margin: 1vmin;
}
