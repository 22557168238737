
.portfolio {
  font-size: clamp(2rem, 10vw, 7rem);
}

.counter-clock-write {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  margin: 0;
  padding: 0;
}

.square-content {
  display: flex;
  flex-direction: column;
  margin: 1vmin;
}

.square-box {
  width: 16vmin;
  height: 100%;
  display: flex;
  align-content: space-around;
  justify-content: center;
  border: 0.1vmin solid white;
}

.upper {
  height: 40%;
  display: flex;
  align-items: center;
  text-align: center;

  div {
    border: 0.1vmin solid white;
    border-radius: 50%;
    width: 90%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    flex-direction: column;
  }
  h1 {
    font-size: clamp(0.5rem, 2.5vw, 1.5rem);
    justify-content: center;
    margin: 0;
  }
}

.inner {
  width: 8vmin;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: center;
  gap: 2ex;

  border-left: 0.1vmin solid white;
  border-right: 0.1vmin solid white;
  h1 {
    margin: 0;
  }
}
