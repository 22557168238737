
.logo-contact {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.icon-text {
  h2 {
    color: white;
  }
}
