@import '../../styles/style.scss';

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 4vmax;
  height: 90vmin;
  display:flex;
  flex-direction: column;
  padding-left: 2vmin;
  padding-top: 1vmin;
  justify-content: space-around;
  align-items: center;
}
